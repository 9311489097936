<template>
	<div class="all-rule-list">
		<el-form :model="dataForm" @keyup.enter.native="getDataList()">
			<el-form-item>
				<el-input style="width: 350px;" v-model="name" placeholder="输入工作室名称关键字查询" clearable>
					<el-button slot="append" icon="el-icon-search" @click="getDataList()"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="dialogVisible=true">添加</el-button>
				<!-- 				<el-button  type="primary"  @click="test()" >新增</el-button> -->
				<el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">删除</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" v-loading="dataListLoading" @selection-change="selectionChangeHandle">
			<el-table-column type="selection" width="50"></el-table-column>
			<el-table-column prop="teamsName" min-width="280" label="工作室名称"></el-table-column>
			<el-table-column prop="entityName" min-width="280" label="管理病种"></el-table-column>
			<el-table-column prop="teamsMemberNumber" min-width="280" label="团队成员数量"></el-table-column>
			<el-table-column prop="teamsPatienceNumber" min-width="280" label="服务患者数量"></el-table-column>
			<el-table-column prop="createTime" min-width="180" label="创建时间"></el-table-column>
			<el-table-column header-align="right" align="right" min-width="200" label="操作">
				<template slot-scope="scope">
					<el-button style="color: #A767FD;" type="text" size="small" @click="gotoEdit(scope.row)">编辑</el-button>
					<!-- 	<el-button style="color: #A767FD;" type="text" size="small" @click="gotoMemberEdit(scope.row)" >成员管理</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="sizeChangeHandle"
			@current-change="currentChangeHandle"
			:current-page="pageIndex"
			:page-sizes="[10, 20, 50, 100]"
			:page-size="pageSize"
			:total="totalPage"
			layout="total, sizes, prev, pager, next, jumper"
		></el-pagination>
		<el-dialog @close="clearDailog" title="添加工作室" :visible.sync="dialogVisible" width="800px">
			<el-form label-width="140px" class="form-con-center4">
				<el-form-item label="头像" required>
					<el-upload
						class="upload-demo"
						:show-file-list="false"
						:before-upload="beforeAvatarUpload"
						style="margin: 20px 0;width: 350px;"
						:action="uploadUrl"
						:on-change="handleChange"
						list-type="picture-card"
						:on-success="handleSuccess"
						:on-remove="handleRemove"
						:before-remove="beforeRemove"
					>
						<img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar">
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item :inline="true" label="工作室名称" required>
					<el-input style="width:350px" v-model="dataForm.teamsName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="管理病种" required>
					<el-select style="width:350px" v-model="dataForm.entityId" filterable>
						<el-option v-for="item in jcbzList" :key="item.value" :value="item.value" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工作室负责人" required>
					<el-select style="width:350px" v-model="dataForm.userId" filterable>
						<el-option v-for="item in userList" :key="item.id" :value="item.id" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工作室介绍">
					<el-input
						:rows="4"
						style="width:350px"
						type="textarea"
						maxlength="90"
						v-model="dataForm.content"
						placeholder="请输入"
					></el-input>
				</el-form-item>
				<el-form-item :inline="true" label="是否开启商城" required>
					<el-radio v-model="dataForm.isShop" :label="1">是</el-radio>
					<el-radio v-model="dataForm.isShop" :label="2">否</el-radio>
				</el-form-item>
				<el-form-item v-if="dataForm.isShop==1" :inline="true" label="商城地址" required>
					<el-input style="width:350px" v-model="dataForm.shopUrl" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addTeam" v-points>确定</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog @close="clearDailog" title="编辑工作室" :visible.sync="dialogVisible2" width="800px">
			<el-form label-width="140px" class="form-con-center4">
				<el-form-item label="头像">
					<el-upload
						class="upload-demo"
						:show-file-list="false"
						:before-upload="beforeAvatarUpload"
						style="margin: 20px 0;"
						:action="uploadUrl"
						:on-change="handleChange"
						list-type="picture-card"
						:on-success="handleSuccess"
						:on-remove="handleRemove"
						:before-remove="beforeRemove"
					>
						<img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar">
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item :inline="true" label="工作室名称" required>
					<el-input style="width:350px" v-model="dataForm.teamsName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="管理病种" required>
					<el-select style="width:350px" v-model="dataForm.entityId" filterable>
						<el-option v-for="item in jcbzList" :key="item.value" :value="item.value" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工作室负责人" required>
					<el-select style="width:350px" v-model="dataForm.userId" filterable>
						<el-option v-for="item in userList" :key="item.id" :value="item.id" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工作室介绍">
					<el-input
						:rows="4"
						style="width:350px"
						type="textarea"
						maxlength="90"
						v-model="dataForm.content"
						placeholder="请输入"
					></el-input>
				</el-form-item>
				<el-form-item :inline="true" label="是否开启商城" required>
					<el-radio v-model="dataForm.isShop" :label="1">是</el-radio>
					<el-radio v-model="dataForm.isShop" :label="2">否</el-radio>
				</el-form-item>
				<el-form-item v-if="dataForm.isShop==1" :inline="true" label="商城地址" required>
					<el-input style="width:350px" v-model="dataForm.shopUrl" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editTeam" v-points>确定</el-button>
				<el-button @click="dialogVisible2 = false">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Qs from "qs";
export default {
  components: {
    // AddOrUpdate
  },

  data() {
    return {
      uploadUrl: "",
      teamsName: "",

      content: "",

      jcbzList: [],
      picUrl: "",
      userList: [],
      dataForm: {
        avatar: "",
        isShop: 2,
        shopUrl: "",
        userId: "",
        teamsName: "",
        entityId: "",
        content: "",
      },
      name: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dialogVisible: false,
      dialogVisible2: false,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },

  computed: {},

  created() {
    this.uploadUrl = this.$global.baseURL + "/aliyun/oss/upload";
    this.getDataList();
    this.getJcbzList();
    this.getList();
  },

  methods: {
    // 获取医生信息
    async getList() {
      const deptId = this.$cookie.get("hospitalId");
      const { data: res } = await this.$http({
        url: this.$http.adornUrl(
          `/organBusiness/backUserList?pageSize=999&pageNo=1&deptId=${deptId}`
        ),
        method: "GET",
      });
      if (res.status) {
        res.data.forEach((item) => {
          item.label = item.organBusiness.name + "-" + item.organBusiness.phone;
          item.id = item.organBusiness.id;
        });
        this.userList = res.data;
      } else {
        this.dataListLoading = false;
        this.$message.error("获取医生信息失败，请重试");
      }
    },
    addTeamMember(teamsId) {
      var that = this;

      let arr = [];

      let param = {
        teamsId: teamsId,
        docterId: this.dataForm.userId,

        roleIds: "e89325119fa411e89e43525400558292",

        entityId: this.dataForm.entityId,
      };
      arr.push(param);

      that
        .$http({
          url: that.$http.adornUrl("/teamsMember/add"),
          method: "post",
          // data: {teamsMembers:JSON.stringify(arr)},
          data: JSON.stringify(arr),
          headers: {
            "Content-Type": "application/json",

            token: this.$cookie.get("token"),
          },
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible = false;
                that.dataForm.content = "";
                that.dataForm.teamsName = "";
                that.dataForm.entityId = "";
                that.dataForm.userId = "";
                that.dataForm.avatar = "";
                that.getDataList();
				that.getGzsInfo()
				
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
	getGzsInfo() {
	  let that = this;
	  that
	    .$http({
	      url: that.$http.adornUrl("/organBusiness/info"),
	      method: "get",
	    })
	    .then(({ data }) => {
	      if (data.data) {
	        this.$store.commit("common/updateImageUrl", data.data.avatar);
	        sessionStorage.organBusinessInfo = JSON.stringify(data.data);
	        if (data.data.teamsManages && data.data.teamsManages.length) {
	          that.teamsManages = data.data.teamsManages;
	          localStorage.teamsManages = JSON.stringify(that.teamsManages);
	          if (data.data.lastTeamId) {
	            
	          } else {
	       that.currentTeam = data.data.teamsManages[0];
	       localStorage.currentTeam = JSON.stringify(
	         data.data.teamsManages[0]
	       );
	          }
	         
	       
	
	          localStorage.gzsFlag = true;
	  
	        } 
			location.reload()
			// that.$router.push('medicine/medicineList')
	      }
	    });
	},
    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let type = lastName.toLowerCase();
      if (this.libraryType == "1") {
        let isLt2M = file.size / 1024 / 1024 < 10;
        if (
          type != ".doc" &&
          type != ".docx" &&
          type != ".pdf" &&
          type != ".xls" &&
          type != ".xlsx" &&
          type != ".ppt" &&
          type != ".pptx"
        ) {
          this.$message.error(
            "上传文件只能是.doc .docx .xls .xlsx .ppt .pptx .pdf 格式!"
          );
          this.fileList = [];
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 10MB!");
          this.fileList = [];
          return false;
        }
        return true;
      } else if (this.libraryType == "2") {
        let isLt2M = file.size / 1024 / 1024 < 50;
        if (type != ".wav" && type != ".mp3" && type != ".wma") {
          this.$message.error("上传文件只能是.wav .mp3 .wma格式!");
          this.fileList = [];
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 50MB!");
          this.fileList = [];
          return false;
        }
        return true;
      } else if (this.libraryType == "3") {
        let isLt2M = file.size / 1024 / 1024 < 200;
        if (type != ".mp4") {
          this.$message.error("上传文件只能是.mp4 .mkv .avi .rmvb格式!");
          this.fileList = [];
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 200MB!");
          this.fileList = [];
          return false;
        }
        return true;
      }
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      // 				console.log(this.fileList)
      // 				let obj={
      // 					url:process.env.BASE_img+ file.response.pic_url,
      // 					fileName:file.name
      // 					}
      // 				this.myFileList.push(obj)
    },
    handleRemove(file, fileList) {
      for (let i = 0; i < this.myFileList.length; i++) {
        if (file.url == this.myFileList[i].url) {
          this.myFileList.splice(i, 1);
        }
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSuccess(response, file, fileList) {
      console.log(response, "response");
      this.dataForm.avatar =
        "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" + response.result.path;
      // this.dataForm.avatar = window.SITE_CONFIG.picUrl + response.pic_url
      this.picUrl =
        "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" + response.result.path;
    },
    gotoEdit(row) {
      Object.assign(this.dataForm, row);

      console.log(this.dataForm);
      delete this.dataForm.teamsMembers;
      this.dataForm.entityId = parseInt(this.dataForm.entityId);

      this.dialogVisible2 = true;
    },
    // 获取数据列表
    getDataList() {
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        teamsName: this.name,
        hospitalId: JSON.parse(localStorage.userInfo).deptPid,
      };
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/teamsManage/list"),
        method: "get",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.dataList = data.data;
        this.totalPage = data.totalCount;

        this.dataListLoading = false;
      });
    },
    //基础病种
    getJcbzList() {
      let deptId = this.$cookie.get("hospitalId");
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: 1,
        pageSize: 9999,
        type: "基础病种",
      };
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/dict/list"),
        method: "get",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.jcbzList = data.data;
      });
    },
    addTeam() {
      var that = this;
      if (this.dataForm.avatar == "") {
        this.$message.warning("请上传头像！");
        return;
      }
      if (this.dataForm.teamsName == "") {
        this.$message.warning("请输入工作室名称！");
        return;
      }
      if (this.dataForm.userId == "") {
        this.$message.warning("请选择工作室负责人！");
        return;
      }
      if (this.dataForm.entityId == "") {
        this.$message.warning("请选择病种！");
        return;
      }
      if (this.dataForm.isShop == null) {
        this.$message.warning("请选择是否开启商城");
        return;
      }
      if (
        this.dataForm.isShop == 1 &&
        (this.dataForm.shopUrl == null || this.dataForm.shopUrl == "")
      ) {
        this.$message.warning("请输入商城地址");
        return;
      }
      var params = {
        teamsName: this.dataForm.teamsName,
        content: this.dataForm.content,
        hospitalId: JSON.parse(localStorage.userInfo).deptPid,
        deptId: JSON.parse(localStorage.userInfo).deptId,
        entityId: this.dataForm.entityId,
        avatar: this.dataForm.avatar,
        userId: this.dataForm.userId,
        isShop: this.dataForm.isShop,
        shopUrl: this.dataForm.shopUrl,
      };
      that
        .$http({
          url: that.$http.adornUrl("/teamsManage/add"),
          method: "post",
          params: params,
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.addTeamMember(data.data.id);
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    editTeam() {
      var that = this;
      if (this.dataForm.isShop == null) {
        this.$message.warning("请选择是否开启商城");
        return;
      }
      if (
        this.dataForm.isShop == 1 &&
        (this.dataForm.shopUrl == null || this.dataForm.shopUrl == "")
      ) {
        this.$message.warning("请输入商城地址");
        return;
      }
      that
        .$http({
          url: that.$http.adornUrl("/teamsManage/update"),
          method: "post",
          data: Qs.stringify(that.dataForm),
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible2 = false;
                that.dataForm.content = "";
                that.dataForm.teamsName = "";
                that.dataForm.entityId = "";
                that.dataForm.userId = "";
                that.dataForm.avatar = "";
                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    clearDailog() {
      var that = this;
      that.dialogVisible2 = false;
      that.dialogVisible = false;
      that.dataForm.content = "";
      that.dataForm.teamsName = "";
      that.dataForm.entityId = "";
      that.dataForm.userId = "";
      that.dataForm.avatar = "";
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },

    gotoRoleInfo(info) {
      this.$router.push({
        path: "/role/role-info",
        query: { id: info.id },
      });
    },
    gotoMemberEdit(info) {
      this.$router.push({
        path: "/team/memberAdd",
        query: {
          id: info.id,
          teamsName: info.teamsName,
          entityId: info.entityId,
        },
      });
      // this.$router.push({
      // 	path: 'team/memberList',
      // 	query: {
      // 		id: info.id,
      // 		teamsName:info.teamsName,
      // 		entityId:info.entityId,
      // 	}
      // })
    },

    // 删除
    deleteHandle(id) {
      for (let item in this.dataListSelections) {
        if (item.teamsMemberNumber != 0) {
          this.$message.warning("工作室已有医生加入，不可删除！");
          return;
        }
      }
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      var ids = {
        userId: this.$cookie.get("userId"),
        ids: userIds.join(","),
      };

      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/teamsManage/delete"),
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

              token: this.$cookie.get("token"),
            },
            data: Qs.stringify(ids),
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.all-rule-list {
  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-dialog {
    width: 600px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #A767FD;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 147px;
    height: 147px;
    line-height: 147px;
    text-align: center;
  }

  .avatar {
    width: 146px;
    height: 146px;
    display: block;
  }
}
</style>